// Imports: Dependencies
import React, { useState, useEffect} from 'react';

// Imports: TypeScript Types
import { NPMAPIResponse } from '../../types/types';

// Component: Downloads (Slide Modal)
const DownloadsSlideModal: React.FC = (): JSX.Element => {
  // React Hooks: State
  const [ downloadsCount, setDownloadsCount ] = useState<string>('');

  // React Hooks: Lifecycle Methods
  useEffect(() => {
    // Date
    let date: string = new Date().toISOString().substring(0, 10);

    // API Request: NPM
    fetch(`https://api.npmjs.org/downloads/point/2021-05-16:${date}/react-native-slide-modal`)
      // Parse JSON
      .then((response: Response) => response.json())
      .then((resultData: NPMAPIResponse) => {
        // Set State
        setDownloadsCount(resultData.downloads.toLocaleString());
      });
  }, []);

  return (
    <div id="npm-downloads-container">
      <p id="npm-downloads-text">{downloadsCount} Downloads</p>
    </div>
  );
};

// Exports
export default DownloadsSlideModal;
