// Imports: Dependencies
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// TypeScript Type: Props
interface Props {
  width: number,
};

// Component: Quikspection Mockup #4
const QuikspectionMockup4: React.FC<Props> = (props) => {
  return (
    <div className="iphone-container" style={{ width: props.width }}>
      <div className="temp-wrapper">
        <div className="px">
          <div className="px__body">
            <div className="notch-container"></div>
            <div className="notch-speaker"></div>
            <div className="notch-camera"></div>

            <div className="mute-button"></div>
            <div className="up-button"></div>
            <div className="down-button"></div>
            <div className="right-button"></div>
          </div>

          <div className="px__screen">
            <div className="px__screen__">
              <div className="screen-frame">
                <StaticImage src="../../images/quik2.png" alt="A dinosaur" />
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  );
};

// Exports
export default QuikspectionMockup4;
