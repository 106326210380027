// Imports: Dependencies
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaGithub, FaNpm, FaAppStoreIos, FaGlobeAmericas } from 'react-icons/fa';
import { IoLogoAndroid } from 'react-icons/io';

// Imports: Components
import DownloadsContactActionSheet from './npm-downloads/DownloadsContactActionSheet';
import DownloadsModalPicker from './npm-downloads/DownloadsModalPicker';
import DownloadsSlideModal from './npm-downloads/DownloadsSlideModal';

// TypeScript Type: Props
interface Props {
  title: string,
  description: string,
  type: string,
  stack: any,
  screenshots: any,
  className?: string,
  githubLink?: string,
  npmLink?: string,
  appleAppStoreLink?: string,
  androidAppStoreLink?: string,
  webLink?: string,
  npmPackageName?: string,
};

// Component: Project
const Project: React.FC<Props> = (props): JSX.Element => {
  // Render Github Link
  const renderGithubLink = (): JSX.Element | undefined => {
    // Check For Link
    if (props.githubLink) {
      return (
        <a id="project-link" href={props.githubLink} target="_blank" rel="noopener noreferrer">
          <FaGithub
            size={25}
            color="black"
          />
        </a>
      );
    }
  };

  // Render NPM Link
  const renderNPMLink = (): JSX.Element | undefined => {
    // Check For Link
    if (props.npmLink) {
      return (
        <a id="project-link" href={props.npmLink} target="_blank" rel="noopener noreferrer">
          <FaNpm
            size={35}
            color="black"
          />
        </a>
      );
    }
  };

  // Render Apple App Store Link
  const renderAppleAppStoreLink = (): JSX.Element | undefined => {
    // Check For Link
    if (props.appleAppStoreLink) {
      return (
        <a id="project-link" href={props.appleAppStoreLink} target="_blank" rel="noopener noreferrer">
          <FaAppStoreIos
            size={30}
            color="black"
          />
        </a>
      );
    }
  };

  // Render Android App Store Link
  const renderAndroidAppStoreLink = (): JSX.Element | undefined => {
    // Check For Link
    if (props.androidAppStoreLink) {
      return (
        <a id="project-link" href={props.androidAppStoreLink} target="_blank" rel="noopener noreferrer">
          <IoLogoAndroid
            size={30}
            color="black"
          />
        </a>
      );
    }
  };

  // Render Web Link
  const renderWebLink = (): JSX.Element | undefined => {
    // Check For Link
    if (props.webLink) {
      return (
        <a id="project-link" href={props.webLink} target="_blank" rel="noopener noreferrer">
          <FaGlobeAmericas
            size={25}
            color="black"
          />
        </a>
      );
    }
  };

  // Render NPM Downloads
  const renderNPMDownloads = (): JSX.Element | undefined => {
    // React Native Contact Action Sheet
    if (props.npmPackageName === 'react-native-contact-action-sheet') {
      return (
        <DownloadsContactActionSheet />
      );
    }
    // React Native Ultimate Modal Picker
    else if (props.npmPackageName === 'react-native-ultimate-modal-picker') {
      return (
        <DownloadsModalPicker />
      );
    }
    // React Native Slide Modal
    else if (props.npmPackageName === 'react-native-slide-modal') {
      return (
        <DownloadsSlideModal />
      );
    }
  };

  return (
    <div id="project-container">
      <h1 id="project-title">{props.title}</h1>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} id="details-container">
          <p id="project-description">{props.description}</p>
          <p id="project-type">{props.type}</p>

          <div id="project-links-container">
            <>{renderGithubLink()}</>

            <>{renderNPMLink()}</>

            <>{renderAppleAppStoreLink()}</>

            <>{renderAndroidAppStoreLink()}</>

            <>{renderWebLink()}</>

            <>{props.npmLink ? renderNPMDownloads() : null}</>
          </div>

          <>{props.stack}</>
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} id="images-container">
          <>{props.screenshots}</>
        </Col>
      </Row>
    </div>
  );
};

// Exports
export default Project;
