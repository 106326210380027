// Imports: Dependencies
import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Page, PageProps } from 'gatsby';

// Imports: Components
import SEO from '../components/seo';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Project from '../components/Project';
import Layout from '../layouts/Layout';

// Imports: Quikspection (Mobile) Screenshots
import QuikspectionMockup1 from '../components/quikspection/QuikspectionMockup1';
import QuikspectionMockup2 from '../components/quikspection/QuikspectionMockup2';
import QuikspectionMockup3 from '../components/quikspection/QuikspectionMockup3';
import QuikspectionMockup4 from '../components/quikspection/QuikspectionMockup4';
import QuikspectionMockup5 from '../components/quikspection/QuikspectionMockup5';
import QuikspectionMockup6 from '../components/quikspection/QuikspectionMockup6';
import QuikspectionMockup7 from '../components/quikspection/QuikspectionMockup7';
import QuikspectionMockup8 from '../components/quikspection/QuikspectionMockup8';
import QuikspectionMockup9 from '../components/quikspection/QuikspectionMockup9';

// Imports: Quikspection (Web) Screenshots
import QuikspectionWebMockup1 from '../components/quikspection-web/QuikspectionWebMockup1';

// Imports: Quikspection (Web) Screenshots
import QuikspectionAdminMockup1 from '../components/quikspection-admin/QuikspectionAdminMockup1';

// Imports: Crosshat (Web) Screenshots
// import CrosschatWebMockup1 from '../components/crosschat-web/CrosschatWebMockup1';

// Imports: Chopp (Mobile) Screenshots
import ChoppMockup1 from '../components/chopp-mobile/ChoppMockup1';
import ChoppMockup2 from '../components/chopp-mobile/ChoppMockup2';
import ChoppMockup3 from '../components/chopp-mobile/ChoppMockup3';
import ChoppMockup4 from '../components/chopp-mobile/ChoppMockup4';
import ChoppMockup5 from '../components/chopp-mobile/ChoppMockup5';
import ChoppMockup6 from '../components/chopp-mobile/ChoppMockup6';

// Imports: React Native Messaging Screenshots
import ReactNativeMessagingMockup1 from '../components/react-native-messaging/ReactNativeMessagingMockup1';
import ReactNativeMessagingMockup2 from '../components/react-native-messaging/ReactNativeMessagingMockup2';
import ReactNativeMessagingMockup4 from '../components/react-native-messaging/ReactNativeMessagingMockup4';

// Imports: Vote Orange County
import VoteOrangeCountyWebMockup1 from '../components/vote-orange-county/VoteOrangeCountyWebMockup1';

// Imports: Covid-19 Tracker
import OrangeCovid19TrackerWebMockup1 from '../components/covid-19-tracker/OrangeCovid19TrackerWebMockup1';
import MaricopaCovid19TrackerWebMockup1 from '../components/covid-19-tracker/MaricopaCovid19TrackerWebMockup1';

// Imports: Jeff Lewis Portfolio Screenshots
import JeffLewisPortfolioWebMockup1 from '../components/portfolio/JeffLewisPortfolioWebMockup1';

// Imports: Modal Picker
import ModalPickerMockup from '../components/modal-picker/ModalPickerMockup';

// Imports Contact Sheet
import ContactSheetMockup from '../components/contact-sheet/ContactSheetMockup';

// Imports React Native Slide Modal Mockup
import ReactNativeSlideModalMockup from '../components/react-native-slide-modal/ReactNativeSlideModalMockup';

// Page: Projects
const Projects: React.FC<PageProps> = (): JSX.Element => {
  return (
    <Layout>
      <SEO title="Projects" />

      <NavBar />

      <div id="projects-feed">
        <Project
          title="Quikspection"
          type="iOS/Android App"
          description="Construction Management & Logistics"
          githubLink=""
          npmLink=""
          webLink="https://www.quikspection.com"
          appleAppStoreLink="INSERT-APPSTORE_LINK_HERE"
          androidAppStoreLink="INSERT-APPSTORE_LINK_HERE"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- TypeScript</p>
                <p className="stack-item">- React Native</p>
                <p className="stack-item">- React Navigation</p>
                <p className="stack-item">- Redux</p>
                <p className="stack-item">- Redux Saga</p>
                <p className="stack-item">- Redux Persist</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- AWS Amplify</p>
                <p className="stack-item">- AWS AppSync</p>
                <p className="stack-item">- AWS Cognito</p>
                <p className="stack-item">- AWS DynamoDB</p>
                <p className="stack-item">- AWS Lambda</p>
                <p className="stack-item">- AWS S3</p>
              </Col>
            </Row>
          }
          screenshots={
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <QuikspectionMockup2 width={220} />

                <QuikspectionMockup1 width={220} />

                <QuikspectionMockup3 width={220} />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <QuikspectionMockup4 width={220} />

                <QuikspectionMockup5 width={220} />

                <QuikspectionMockup8 width={220} />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <QuikspectionMockup6 width={220} />

                <QuikspectionMockup7 width={220} />

                <QuikspectionMockup9 width={220} />
              </div>
            </div>
          }
        />

        <Project
          title="Quikspection Web"
          type="Web App"
          description="Construction Management & Logistics"
          githubLink=""
          npmLink=""
          webLink="https://www.quikspection.com"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- HTML</p>
                <p className="stack-item">- CSS</p>
                <p className="stack-item">- TypeScript</p>
                <p className="stack-item">- React</p>
                <p className="stack-item">- React Reactor DOM</p>
                <p className="stack-item">- Redux</p>
                <p className="stack-item">- Redux Saga</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- Redux Persist</p>
                <p className="stack-item">- Axios</p>
                <p className="stack-item">- AWS Amplify</p>
                <p className="stack-item">- AWS AppSync</p>
                <p className="stack-item">- AWS Cognito</p>
                <p className="stack-item">- AWS DynamoDB</p>
                <p className="stack-item">- AWS Lambda</p>
                <p className="stack-item">- AWS S3</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <QuikspectionWebMockup1 url={'quikspection.com'} />
            </div>
          }
        />

        <Project
          title="Quikspection Admin"
          type="Web App"
          description="Construction Management & Logistics"
          githubLink=""
          npmLink=""
          webLink="https://www.quikspection.com"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- HTML</p>
                <p className="stack-item">- CSS</p>
                <p className="stack-item">- TypeScript</p>
                <p className="stack-item">- React</p>
                <p className="stack-item">- React Reactor DOM</p>
                <p className="stack-item">- Redux</p>
                <p className="stack-item">- Redux Saga</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- Redux Persist</p>
                <p className="stack-item">- Axios</p>
                <p className="stack-item">- AWS Amplify</p>
                <p className="stack-item">- AWS AppSync</p>
                <p className="stack-item">- AWS Cognito</p>
                <p className="stack-item">- AWS DynamoDB</p>
                <p className="stack-item">- AWS Lambda</p>
                <p className="stack-item">- AWS S3</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <QuikspectionAdminMockup1 url={'quikspection.com'} />
            </div>
          }
        />

        <Project
          className="project-container"
          title="Chopp (In Progress)"
          type="iOS/Android App"
          description="Recipe Cookbook & Grocery List Generator"
          githubLink=""
          npmLink=""
          appleAppStoreLink="INSERT-APPSTORE_LINK_HERE"
          androidAppStoreLink="INSERT-APPSTORE_LINK_HERE"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- JavaScript</p>
                <p className="stack-item">- React Native</p>
                <p className="stack-item">- React Hooks</p>
                <p className="stack-item">- React Navigation</p>
                <p className="stack-item">- Redux</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- Redux Saga</p>
                <p className="stack-item">- Redux Persist</p>
                <p className="stack-item">- Firebase Authentication</p>
                <p className="stack-item">- Firebase Cloud Firestore</p>
                <p className="stack-item">- Firebase Cloud Functions</p>
              </Col>
            </Row>
          }
          screenshots={
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <ChoppMockup1 width={220} />

                <ChoppMockup2 width={220} />

                <ChoppMockup3 width={220} />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <ChoppMockup4 width={220} />

                <ChoppMockup5 width={220} />

                <ChoppMockup6 width={220} />
              </div>
            </div>
          }
        />

        <Project
          className="project-container"
          title="Vote Orange County"
          type="Web App"
          description="Voting & Elections"
          githubLink=""
          npmLink=""
          webLink="https://voteorangecounty.com"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
              <p className="stack-item">- TypeScript</p>
                <p className="stack-item">- React</p>
                <p className="stack-item">- HTML</p>
                <p className="stack-item">- CSS</p>
                <p className="stack-item">- Gatsby</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- Bootstrap</p>
                <p className="stack-item">- Chart.js</p>
                <p className="stack-item">- Netlify</p>
                <p className="stack-item">- Jest</p>
                <p className="stack-item">- Google Analytics</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <VoteOrangeCountyWebMockup1 url="voteorangecounty.com/" />
            </div>
          }
        />

        <Project
          className="project-container"
          title="Orange County, CA COVID-19 Tracker"
          type="Web App"
          description="Orange County, CA COVID-19 Tracker"
          githubLink=""
          npmLink=""
          webLink="https://orangecountycovid19.com"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
              <p className="stack-item">- TypeScript</p>
                <p className="stack-item">- React</p>
                <p className="stack-item">- HTML</p>
                <p className="stack-item">- CSS</p>
                <p className="stack-item">- Gatsby</p>
                <p className="stack-item">- Bootstrap</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- Axios</p>
                <p className="stack-item">- Chart.js</p>
                <p className="stack-item">- Netlify</p>
                <p className="stack-item">- Jest</p>
                <p className="stack-item">- Google Analytics</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <OrangeCovid19TrackerWebMockup1 url="orangecountycovid19.com" />
            </div>
          }
        />

        <Project
          className="project-container"
          title="Maricopa County, AZ COVID-19 Tracker"
          type="Web App"
          description="Maricopa County, AZ COVID-19 Tracker"
          githubLink=""
          npmLink=""
          webLink="https://maricopacountycovid19.com"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- TypeScript</p>
                <p className="stack-item">- React</p>
                <p className="stack-item">- HTML</p>
                <p className="stack-item">- CSS</p>
                <p className="stack-item">- Gatsby</p>
                <p className="stack-item">- Bootstrap</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- Axios</p>
                <p className="stack-item">- Chart.js</p>
                <p className="stack-item">- Netlify</p>
                <p className="stack-item">- Jest</p>
                <p className="stack-item">- Google Analytics</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <MaricopaCovid19TrackerWebMockup1 url="maricopacountycovid19.com" />
            </div>
          }
        />

        {/* <Project
          className="project-container"
          title="Crosschat (In Progress)"
          type="Web App"
          description="Crossplatform Messaging (Mobile App <--> Web App)"
          githubLink=""
          npmLink=""
          webLink="INSERT_WEB_LINK_HERE"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- HTML</p>
                <p className="stack-item">- CSS</p>
                <p className="stack-item">- JavaScript</p>
                <p className="stack-item">- React</p>
                <p className="stack-item">- React Hooks</p>
                <p className="stack-item">- React Router DOM</p>
                <p className="stack-item">- Redux</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- Redux Saga</p>
                <p className="stack-item">- Redux Persist</p>
                <p className="stack-item">- Axios</p>
                <p className="stack-item">- Firebase Authentication</p>
                <p className="stack-item">- Firebase Cloud Firestore</p>
                <p className="stack-item">- Firebase Cloud Functions</p>
                <p className="stack-item">- Firebase Cloud Messaging</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <CrosschatWebMockup1 url={'crosschat.com'} />
            </div>
          }
        /> */}

        <Project
          className="project-container"
          title="Crosschat (In Progress)"
          type="iOS/Android App"
          description="Crossplatform Messaging (Mobile App <--> Web App)"
          githubLink=""
          npmLink=""
          appleAppStoreLink="INSERT-APPSTORE_LINK_HERE"
          androidAppStoreLink="INSERT-APPSTORE_LINK_HERE"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- JavaScript</p>
                <p className="stack-item">- React Native</p>
                <p className="stack-item">- React Hooks</p>
                <p className="stack-item">- React Navigation</p>
                <p className="stack-item">- Redux</p>
                <p className="stack-item">- Redux Saga</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- Redux Persist</p>
                <p className="stack-item">- Axios</p>
                <p className="stack-item">- Firebase Authentication</p>
                <p className="stack-item">- Firebase Cloud Firestore</p>
                <p className="stack-item">- Firebase Cloud Functions</p>
                <p className="stack-item">- Firebase Cloud Messaging</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <ReactNativeMessagingMockup4 width={220} />

              <ReactNativeMessagingMockup1 width={220} />

              <ReactNativeMessagingMockup2 width={220} />
            </div>
          }
        />

        <Project
          className="project-container"
          title="Jeff Lewis Portfolio"
          type="Web App"
          description="Developer Portfolio"
          githubLink=""
          npmLink=""
          webLink="https://www.jeffreyallenlewis.com"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- TypeScript</p>
                <p className="stack-item">- React</p>
                <p className="stack-item">- HTML</p>
                <p className="stack-item">- CSS</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- Gatsby</p>
                <p className="stack-item">- Bootstrap</p>
                <p className="stack-item">- Netlify</p>
                <p className="stack-item">- Google Analytics</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <JeffLewisPortfolioWebMockup1 url="jeffreyallenlewis.com" />
            </div>
          }
        />

        <Project
          className="project-container"
          title="React Native Ultimate Modal Picker"
          type="NPM Package"
          description="Date, Time, Date Range, DateTime, List, & State Modal Pickers (iOS/Android)"
          githubLink="https://github.com/jefelewis/react-native-ultimate-modal-picker"
          npmLink="https://www.npmjs.com/package/react-native-ultimate-modal-picker"
          npmPackageName="react-native-ultimate-modal-picker"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- TypeScript</p>
                <p className="stack-item">- React Native</p>
                <p className="stack-item">- React Native Modal</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- React Native Datetime Picker</p>
                <p className="stack-item">- React Native Picker</p>
                <p className="stack-item">- React Hooks</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <ModalPickerMockup width={300} />
            </div>
          }
        />

        <Project
          className="project-container"
          title="React Native Contact Action Sheet"
          type="NPM Package"
          description="Contact Action Sheet for Calling, Messaging, & Emailing (iOS/Android)"
          githubLink="https://github.com/jefelewis/react-native-contact-action-sheet"
          npmLink="https://www.npmjs.com/package/react-native-contact-action-sheet"
          npmPackageName="react-native-contact-action-sheet"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- TypeScript</p>
                <p className="stack-item">- React Native</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- React Native Modal</p>
                <p className="stack-item">- React Hooks</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <ContactSheetMockup width={300} />
            </div>
          }
        />

        <Project
          className="project-container"
          title="React Native Slide Modal"
          type="NPM Package"
          description="Bottom Sheet Modal & Form Sheet Modal (iOS/Android)"
          githubLink="https://github.com/jefelewis/react-native-slide-modal"
          npmLink="https://www.npmjs.com/package/react-native-slide-modal"
          npmPackageName="react-native-slide-modal"
          stack={
            <Row id="stack-container">
              <Col xs={6}>
                <p className="stack-item">- TypeScript</p>
                <p className="stack-item">- React Native</p>
              </Col>

              <Col xs={6}>
                <p className="stack-item">- React Hooks</p>
              </Col>
            </Row>
          }
          screenshots={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <ReactNativeSlideModalMockup width={300} />
            </div>
          }
        />
      </div>

      <Footer />
    </Layout>
  );
};

// Exports
export default Projects;
