// Imports: Dependencies
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// TypeScript Type: Props
interface Props {
  url: string,
};

// Component: Orange COVID-19 Tracker Web Mockup #1
const OrangeCovid19TrackerWebMockup1: React.FC<Props> = (props): JSX.Element => {
  return (
    <div id="macbook-container">
      <div id="macbook-pro-app">
        <div id="web-browser-header">
          <div id="dots-container">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>

          <div id="arrows-container">
            <div className="arrow-container">
              <i className="arrow-left"></i>
            </div>

            <div className="arrow-container">
              <i className="arrow-right"></i>
            </div>
          </div>

          <div id="url-container">
            <a href={`https://www.${props.url}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <p id="url-text">{props.url}</p>
            </a>
          </div>
        </div>

        <StaticImage src="../../images/covid19tracker7.png" alt="A dinosaur" />
      </div>

      <div id="macbook-screen"></div>
      <div id="macbook-body"></div>
      <div id="macbook-foot"></div>
    </div>
  );
};

// Exports
export default OrangeCovid19TrackerWebMockup1;
